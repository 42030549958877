// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "bootstrap/js/dist/carousel"
import "bootstrap/js/dist/collapse"
import "bootstrap/js/dist/dropdown"
import "bootstrap-icons/font/bootstrap-icons.css"
import "channels"
import "controllers"
import "css/site"

Rails.start()
ActiveStorage.start()
