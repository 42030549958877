import ApexCharts from 'apexcharts'
import { Controller } from '@hotwired/stimulus'
import { to_milliseconds } from '../util/timeseries'

export default class extends Controller {
    static values = {
        instanceId: Number,
    }

    static targets = [
        "cpu",
        "mem",
        "disk",
    ]

    connect() {
        this.update()
    }

    update() {
        fetch(window.location + '/charts')
        .then((response) => {
            if (!response.ok) {
                console.log("error fetching charts:", response.body);
                return
            }
            response
                .json()
                .then((data) => this.loadData(data));
        });
    }

    loadData(data) {
        this.cpuChart = new ApexCharts(this.cpuTarget, {
            chart: {
                type: 'line',
                toolbar: { show: false }
            },
            title: {
                text: 'CPU Usage'
            },
            tooltip: {
                x: { format: 'HH:mm' }
            },
            series: [{
                name: 'CPU Usage',
                data: to_milliseconds(data['cpu_usage'])
            }],
            xaxis: {
                type: 'datetime',
                title: {
                    text: 'UTC'
                }
            },
            yaxis: {
                tickAmount: 4,
                labels: {
                    formatter: function(value) {
                        return Math.round(value * 100) + "%"
                    }
                },
                min: 0,
                max: 1
            },
            colors: ['#4caf50', '#FF4560']
        });

        this.memChart = new ApexCharts(this.memTarget, {
            chart: {
                type: 'line',
                toolbar: { show: false }
            },
            tooltip: {
                x: { format: 'HH:mm' }
            },
            title: {
                text: 'Memory Usage'
            },
            series: [{
                name: 'Memory Usage',
                data: to_milliseconds(data['mem_usage'])
            },{
                name: 'Memory Limit',
                data: to_milliseconds(data['mem_limits'])
            }],
            xaxis: {
                type: 'datetime',
                title: {
                    text: 'UTC'
                }
            },
            yaxis: {
                tickAmount: 4,
                labels: {
                    formatter: function(value) {
                        return Math.round((value / 1073741824) * 10) / 10 + " GB"
                    }
                },
                min: 0
            },
            colors: ['#4caf50', '#FF4560']
        });

        this.diskChart = new ApexCharts(this.diskTarget, {
            chart: {
                type: 'line',
                toolbar: { show: false }
            },
            title: {
                text: 'Disk Usage'
            },
            tooltip: {
                x: { format: 'HH:mm' }
            },
            series: [{
                name: 'Disk Usage',
                data: to_milliseconds(data['disk_usage'])
            },{
                name: 'Disk Limit',
                data: to_milliseconds(data['disk_limits'])
            }],
            xaxis: {
                type: 'datetime',
                title: {
                    text: 'UTC'
                }
            },
            yaxis: {
                tickAmount: 4,
                labels: {
                    formatter: function(value) {
                        return Math.round((value / 1073741824) * 10) / 10 + " GB"
                    }
                },
                min: 0
            },
            colors: ['#4caf50', '#FF4560']
        });

        this.cpuChart.render();
        this.memChart.render();
        this.diskChart.render();
    }
}
