import consumer from './consumer'

const MaxLineCount = 1024

function createChannel(element) {
    const consoleText = element.getElementsByClassName('console-text')[0]
    const form = element.getElementsByClassName('consoleInputForm')[0]
    const instance = element.getAttribute('data-instance-id')
    const loadingSpinner = element.getElementsByClassName('loading-control')[0]
    console.log("Console starting for instance", instance, form)

    consumer.subscriptions.create({ channel: "ConsoleChannel", instance }, {
        initialized() {
            console.log("Console", instance, "initialized.")

            this.onLoad = this.onLoad.bind(this)
            this.uninstall = this.uninstall.bind(this)
            this.formInput = this.formInput.bind(this)
            this.install()

            form.onsubmit = this.formInput
        },

        connected() {
            console.log("Console", instance, "connected.")

            loadingSpinner.style.display = "none"
            const textInput = form.elements["consoleSend"]
            textInput.removeAttribute('disabled')
        },

        disconnected() {
            console.log("Console", instance, "disconnected.")
            
            loadingSpinner.style.display = "block"
            const textInput = form.elements["consoleSend"]
            textInput.setAttribute('disabled', '')
            this.clearBuffer()
        },
    
        rejected() {
            console.log("Console", instance, "rejected.")
            this.clearBuffer()
        },

        sendInput(data) {
            this.perform("sendInput", { line: data })
        },

        received(data) {
            if (Array.isArray(data)) {
                data.forEach((lineData) => this.appendLine(lineData))
            } else {
                this.appendLine(data)
            }
        },

        appendLine(data) {
            const element = this.createLine(data)

            // Current Line Count is +1 because this function adds a line at the end and the
            // number of lines should not exceed MaxLineCount at the end.
            const currentLineCount = consoleText.childElementCount + 1

            const linesToBeRemoved = currentLineCount - MaxLineCount
            if (linesToBeRemoved > 0) {
                Array.prototype.slice.call(consoleText.children, 0, linesToBeRemoved).forEach((elem) => {
                    elem.remove()
                })
            }
            consoleText.insertAdjacentElement("beforeend", element)
        },

        createLine(data) {
            const li = document.createElement("li")
            if (data.hasOwnProperty("message")) {
                li.textContent = data['message'];
            } else {
                li.textContent = data;
            }
            return li
        },

        clearBuffer() {
            consoleText.replaceChildren()
        },

        onLoad() {
            console.log("Console", instance, "stopping due to page load.")
            this.unsubscribe()
            this.uninstall()
        },

        install() {
            document.addEventListener("turbolinks:load", this.onLoad)
        },

        uninstall() {
            document.removeEventListener("turbolinks:load", this.onLoad)
        },

        formInput(event) {
            event.preventDefault();
            const textInput = event.target.elements["consoleSend"]
            this.sendInput(textInput.value)
            textInput.value = ""
        }
    });
}

function loadConsoleChannels() {
    const roots = document.getElementsByClassName("instance-console")

    Array.prototype.forEach.call(roots, (element) => {
        createChannel(element)
    });
}

function onLoad() {
    loadConsoleChannels()
}
window.addEventListener("load", onLoad)
