import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    gameVersions: Object,
    game: Number,
    gameVersion: Number,
    region: Number,
  }

  static targets = [
      "game",
      "gameVersion",
      "gameVersionDiv",
      "region",
  ]

  updateGame(e) {
    if (Object.hasOwn(this.gameVersionsValue, e.target.value)) {
      let options = this.gameVersionsValue[e.target.value].map(x => new Option(x["name"], x["id"]))
      this.gameVersionTarget.replaceChildren(...options)
      this.gameVersionDivTarget.hidden = false
    } else {
      this.gameVersionTarget.replaceChildren()
      this.gameVersionDivTarget.hidden = true
    }
  }
}
